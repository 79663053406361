const state = {
  lang: 'en',
};

const actions = {
  change({ commit }, lang) {
    commit('change', lang);
  },
};

const mutations = {
  change(state, lang) {
    state.lang = lang;
  },
};

export const lang = {
  namespaced: true,
  state,
  actions,
  mutations
};
<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-data-table
                :headers="headers"
                :items="items"
                item-key="key_login"
                :loading="Overlay"
                loading-text="Loading... Please wait"
                :footer-props="{itemsPerPageText: nn('__DATA_ROWS__',lang)}"
                :items-per-page="20"
            >
                <template v-slot:[`item.ip`]="{ item }">
                    <span class="justify">{{ item.ip }} <flag :iso="item.country.toString().toLowerCase()"/></span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    {{ action_details[item.action] }}
                </template>
                <template v-slot:[`item.time`]="{ item }">
                    {{ doParseTime(item.time) }}
                </template>

            </v-data-table>
        </v-layout>
    </v-container>
  </template>
  
  <script>
  import { fetchData, doParseTime } from '../helpers';
  import { mapState, mapActions } from 'vuex'
  import languageHelper from '../helpers/language';
  const nn = languageHelper.nn;
  export default {
    name: "Logs",
    computed:{
        ...mapState('lang', ['lang']),
        ...mapState('auth', ['status']),
        ...mapState('SellerStats', ['SellerStats']),
        ...mapState({
            alert: state => state.alert
        }),
        headers(){
            return [
                { text: "IP", align: 'right', sortable: false,value: 'ip',width:"auto" },
                { text: "User-Agent", align: 'left', sortable: true,value: 'ua',width:"auto"},
                { text: "Time", align: 'left', sortable: true,value: 'time',width:"auto" },
                { text: "Action", align: 'left', sortable: false,value: 'action',width:"auto" },
            ]
        },
        action_details(){
            return {
                1: "LOGIN",
                2: "CHARGE",
                3: "DELETE",
            }
        }
    },
    methods:{
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        ...languageHelper,
        doParseTime,
        fetchData: function(){
            this.Overlay = true;
            fetchData(`${this.$API}/api/logs/show`).then((resp)=>{
                this.items = resp;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
    },
    data : () => ({
        items: [],
        Overlay: false,

    }),
    mounted(){
        this.fetchData();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
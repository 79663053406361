<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ nn(alert.message,lang) }}
    </v-alert>
    <v-layout row wrap>
    <template>
    <v-flex
      sm=12
      xs=12
      md=12
      lg=12
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols=12 lg=3 xl=2>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="nn('__SEARCH__',lang)"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          item-key="key"
          :loading="Overlay"
          loading-text="Loading... Please wait"
          :footer-props="{itemsPerPageText: nn('__DATA_ROWS__',lang)}"
          :items-per-page="20"
        >
      <template v-slot:top>
      </template>
					<template v-slot:[`item.key`]="{ item }">
						<v-btn
							text
							color="success"
							medium
							v-clipboard="item.key"
							class="key"
						>
							<v-icon left>mdi-check</v-icon>
							{{item.key}}
							<v-icon right>mdi-content-copy</v-icon>
						</v-btn>
					</template>
					<template v-slot:[`item.expire`]="{ item }">
						<template v-if="item.expire !== -1">
							{{ doParseTime(item.expire) }}
						</template>
						<template>
							LIFE TIME
						</template>
          </template>
					<template v-slot:[`item.hwid`]="{ item }">
						{{ item.total }} / {{item.limit}}
						<v-list>
							<v-list-item v-for="(hwid,index) in item.hwid" :key="index">
								<v-list-item-title>Name: {{hwid.name}}</v-list-item-title>
								<v-list-item-title>HWID: {{hwid.hwid}}</v-list-item-title>
                <v-list-item-subtitle><v-icon right :color="getOnline(hwid) > 0 ? 'green':'red'">mdi-lightning-bolt-circle</v-icon> Last Online: {{getLastOnline(hwid)}}</v-list-item-subtitle>
								<v-list-item-subtitle v-if="item['last_status'] !== 'Pending'">
									<v-btn
										color="red"
										x-small
										@click="doUnBindHWID(item.key_id, hwid.hwid)"
									>
										Unbind
									</v-btn>
								</v-list-item-subtitle>
							</v-list-item>
						</v-list>
						<template v-if="item.total < item.limit">
							<br />
							<v-btn
								color="green"
								x-small
								@click="doOpenBoxBind(item)"
								class="ma-2"
							>
								BIND HWID
							</v-btn>
							<br />
						</template>
					</template>
      </v-data-table>
      </v-card>
    </v-flex>
    </template>
    </v-layout>
		<v-dialog
			max-width="600px"
			persistent
			v-model="boxBind"
		>
			<v-card flat>
				<v-card-title>Bind HWID to Key</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="bindData.HWID"
						label="HWID"
					/>
					<v-text-field
						v-model="bindData.Name"
						label="Name"
					/>
					<div v-if="errorSubmit">
						<br />
						<v-alert
							text
							prominent
							type="error"
							icon="mdi-cloud-alert"
							timeout="5000"
						>
							{{ errorSubmit }}
						</v-alert>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn
						@click="boxBind = false"
					>
						Close
					</v-btn>
					<v-btn
						color="green"
						@click="doBindHWID"
					>
						Bind
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-overlay :value="OverlayBind">
				<v-progress-circular
					indeterminate
					size="64"
				></v-progress-circular>
			</v-overlay>
		</v-dialog>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { fetchData } from '../helpers';
import languageHelper from '../helpers/language';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
const nn = languageHelper.nn
export default {
  name: 'PublicKey',
  directives: { clipboard },
  components: {
  },
	data : () => ({
		OverlayBind: false,
		errorSubmit: "",
		bindData:{},
		boxBind: false,
		listKeys: "",
		isTestKey: false,
		menu: false,
		valid: false,
		showFilter: {
			status: 1,
			key_package: null,
			created: null,
			hwid: null,
			key_format: 1
		},
		newKey:{
			num: 1,
			package: 1
		},
		extraTimeAll:true,
		addExtraMenu: false,
		extraTimeKey: "",
		extraTime: 0,
		extraTimeExpired: false,
		modifyMode: false,
		Overlay: false,
		dialog: false,
		search: '',
		items: [],
		sellers: ["All"],
		updateMode: false,
		packageList: ['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
		keyFormatList: [{value:1,text:'Name_'},{value:2,text:'Name-'},{value:3,text:'Name'}],
		//packagesKey:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
		topupSellerData: {},
		topupMethod: "",
		PackageColors: {"1DAY":"cyan lighten-3","7DAY":"cyan lighten-2","15DAY":"cyan darken-2","30DAY":"cyan darken-4","2HOUR":"green darken-1"},
		showNews:false,
	}),
	computed: {
      ...mapState('lang', ['lang']),
      ...mapState('auth', ['status']),
      ...mapState('SellerStats', ['SellerStats']),
      ...mapState({
          alert: state => state.alert
      }),
      FilterCreated(){
       return [
         {title:nn('__ALL__',this.lang),search:null},
         {title:nn('__SEARCHKEYS_1DAY__',this.lang),search:86400},
         {title:nn('__SEARCHKEYS_1WEEK__',this.lang),search:604800}]
      },
      FilterHWID(){
        return [
          {title:nn('__ALL__',this.lang),search:null},
          {title:nn('__SEARCHHWID_SINGLE__',this.lang),search:false},
          {title:nn('__SEARCHHWID_MULTI__',this.lang),search:true}
        ]
      },
      FilterUsed(){
        return [
          {title:nn('__ALL__',this.lang),search:null},
          {title:nn('__SEARCHSTATUS_UNUSED__',this.lang),search:1},
          {title:nn('__SEARCHSTATUS_USED__',this.lang),search:2},
          {title:nn('__SEARCHSTATUS_EXPIRED__',this.lang),search:3},
          {title:nn('__SEARCHSTATUS_REGENERATED__',this.lang),search:6}
        ]
      },
      packages(){
        return [
          {title:nn('__ALL__',this.lang), search:null}, 
          {title:nn('__SEARCHPACKAGE_2HOUR__',this.lang), search:'2HOUR'}, 
          {title:nn('__SEARCHPACKAGE_1DAY__',this.lang), search:'1DAY'},  
          {title:nn('__SEARCHPACKAGE_7DAY__',this.lang), search:'7DAY'},  
          {title:nn('__SEARCHPACKAGE_15DAY__',this.lang), search:'15DAY'},  
          {title:nn('__SEARCHPACKAGE_30DAY__',this.lang), search:'30DAY'}
        ]
      },
      headers(){
        return [
            { text: nn('__DATAKEY__',this.lang), align: 'right', sortable: false,value: 'key',width:"auto" },
            { text: nn('__EXPIREDATE__',this.lang), align: 'left', sortable: true,value: 'expire',width:"auto"},
            { text: nn('__DATAHWID__',this.lang), align: 'left', sortable: true,value: 'hwid',width:"auto" },
            { text: 'LAST STATUS', align: 'left', sortable: true,value: 'last_status',width:"auto" },
        ]
      },
      wallet(){
        return this.$store.state.SellerStats.SellerStats.balance;
      },
      testkeys(){
        return this.$store.state.SellerStats.SellerStats.testkeys;
      },
      packagesKey(){
        const price = this.$store.state.SellerStats.SellerStats.price;
        let newPackage = [];
        price.forEach((element,index) => {
          if(element > 0)
          {
            newPackage.push({value:index,text:`${this.packageList[index]} - ${element}`});
          }
        });
        return newPackage;
      },
      totalValue(){
        const keys = this.newKey.num;
        const priceSeller = this.$store.state.SellerStats.SellerStats.price;
        const price = (this.isTestKey) ? 1:priceSeller[this.newKey.package];
        let totalValue = keys*price;
        return totalValue;
      },
      totalValueValid(){
        const keys = this.newKey.num;
        const priceSeller = this.$store.state.SellerStats.SellerStats.price;
        const price = (this.isTestKey) ? 1:priceSeller[this.newKey.package];
        let totalValue = keys*price;
        let valid = (this.isTestKey) ? (totalValue > this.testkeys):(totalValue > this.wallet);
        return valid;
      }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    ...languageHelper,
    getTimeDiff(item){
    	if(item.last_online === -1) return -1;
    	const dateObj = new Date(item.last_online*1000);
      const currentTime = new Date();
			const timeDifferenceInSeconds = Math.floor((currentTime-dateObj)/1000);
			return timeDifferenceInSeconds;
    },
    getLastOnline(item){
      if(item.last_online === -1){
        return "Offline 3days ago";
      }else{
      	const dateObj = new Date(item.last_online*1000);
        const timeDifferenceInSeconds = this.getTimeDiff(item);
        if (timeDifferenceInSeconds > 0){
					if(timeDifferenceInSeconds < 3600){
				    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
				    if(minutesAgo === 0){
				    	return `Playing`;
				    }
				    return `${minutesAgo} mins ago`;
					}
					if(timeDifferenceInSeconds < 86400){
					    // Less than 24 hours ago
					    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
					    return `${hoursAgo} hours ago`;
					}
				}
	    	return dateObj.toLocaleString();
      }
    },
    getOnline(item){
      const timeDifferenceInSeconds = this.getTimeDiff(item);
      if (timeDifferenceInSeconds <= 300 && timeDifferenceInSeconds !== -1 || timeDifferenceInSeconds == NaN) return true;
      return false;
    },
		doUnBindHWID(key_id, hwid){
			this.OverlayBind = true;
			fetchData(`${this.$API}/api/stream/remove-hwid`,{ key_id, HWID: hwid }).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.boxBind = false;
					this.success(message);
					this.fetchData();
				}else{
					this.errorSubmit = message;
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error("SERVER ERROR !!!");
				}
			}).finally(()=>{
				this.OverlayBind = false;
			});
		},
		doOpenBoxBind(item){
			this.bindData = {
				key_id:item.key_id
			};
			this.errorSubmit = "";
			this.boxBind = true;
		},
		doBindHWID(){
			const {key_id, HWID, Name} = this.bindData;
			if(!HWID || !key_id){
				this.errorSubmit = "INVALID DATA";
				return;
			}
			this.OverlayBind = true;
			fetchData(`${this.$API}/api/stream/add-hwid`,{ key_id, HWID, Name }).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.boxBind = false;
					this.success(message);
					this.fetchData();
				}else{
					this.errorSubmit = message;
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error("SERVER ERROR !!!");
				}
			}).finally(()=>{
				this.OverlayBind = false;
			});
		},
    doCloseNews(){
      this.showNews=false;
      this.$cookies.set('read_news',Math.floor(Date.now() / 1000));
    },
    filterData(){
      const filter = this.showFilter;
      const cleanFilter = this.clean(filter);
      const {username,status,key_package,created,hwid} = cleanFilter;
      return {username,status,key_package,created,hwid};
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/stream/all`,request).then((resp)=>{
          this.items = resp;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        if(!this.showFilter.key_package) this.showFilter.key_package = null
        if(!this.showFilter.created) this.showFilter.created = null
        if(!this.showFilter.hwid) this.showFilter.hwid = null
        this.Overlay = false;
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
          delete obj[propName];
        }
      }
      return obj
    },
    doCopyKey: function(item){
      if(item.key_status === 1){
          return item.key;
      }
    },
    doParseTime: function(data){
      var dt = new Date(data*1000);
      return dt.toLocaleString();
    },
  },
  watch:{
    SellerStats:function(){
      this.SellerStats.last_time_read = parseInt(this.$cookies.get('read_news'));
      const timeOut = isNaN(this.SellerStats.last_time_read) ? true:(parseInt(this.SellerStats.last_time_read) < parseInt(this.SellerStats.last_update_news));
      this.showNews = (this.SellerStats.showNews && timeOut );
    }
  },
  mounted(){
    this.fetchData();
  }
}
</script>
<style scoped>
.key{
  text-transform: none;
}
</style>
import { fetchData } from '../../helpers';
const state = {
    SellerStats: {
        balance: 0,
        testkeys: 0,
        price: [],
        keys: [],
        enableTopup: false,
        username: "",
        social: {},
        topup_package: [],
        news: "",
        last_update_news:0,
        showNews: false,
        seller_news: "",
        last_time_read:0,
        showNewsStatus: false,
        shop_seller: {},
        plans: [],
    }
};

const actions = {
    updateData(context,{API}){
        fetchData(`${API}/api/stats`).then((json)=>{
            context.commit('newSellerStats',json);
        });
    },
    showStatsNew(context){
        context.commit('newSellerStatsNews');
    },
};
const mutations = {
    newSellerStats(state,newData){
        state.SellerStats = newData;
    },
    newSellerStatsNews(state){
        state.SellerStats.last_time_read = -1;
    }
};

export const SellerStats = {
    namespaced: true,
    state,
    actions,
    mutations
};
import Vue from 'vue';
import Vuex from 'vuex';
import { lang } from './modules/lang';
import { alert } from './modules/alert';
import { auth } from './modules/auth';
import { SellerStats } from './modules/SellerStats';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    alert,
    lang,
    SellerStats
  }
});
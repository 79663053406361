<template>
  <v-flex>
		<v-alert
			dense
			:type="alert.type"
			timeout="5000"
			v-if="alert.message"
			class="block"
		>
			{{ nn(alert.message,lang) }}
		</v-alert>
		<v-row>
			<v-col cols="auto">
				<v-btn
					color="green"
					@click="doUpdateStatusAllKey(1)"
				>
					UNLOCK ALL KEY
				</v-btn>
			</v-col>
			<v-col cols="auto">
				<v-btn
					color="red"
					@click="doUpdateStatusAllKey(0)"
				>
					LOCK ALL KEY
				</v-btn>
			</v-col>
      <v-col cols="auto">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            solo
            auto
            clearable
        />
      </v-col>
      <v-col cols="auto">
        <v-select
            :items="shopNames"
            label="SHOP"
            v-model="searchByShopName"
            @change="searchByShopName = $event"
            item-text="name"
            item-value="name"
            return-object
            single-line
            hide-details
            solo
            auto
        />
      </v-col>
      <v-col cols="auto">
        <v-select
         :items="keyPackages"
         label="SHOP"
         v-model="searchByPackage"
         @change="searchByPackage = $event"
         item-text="name"
         item-value="name"
         return-object
         single-line
         hide-details
         solo
         auto
        />
      </v-col>
		</v-row>
		<br />
		<v-divider />
		<br />
    <v-data-table
        :headers="headers"
        :items="Items"
        :search="search"
        item-key="key_login"
        :loading="Overlay"
        loading-text="Loading... Please wait"
        :footer-props="{itemsPerPageText: nn('__DATA_ROWS__',lang)}"
        :items-per-page="20"
    >
			<template v-slot:[`item.ips`]="{ item }">
				<template v-if="item.ips.length > 0">
					<div style="max-height: 220px; overflow-y: auto;">
						<v-chip v-for="ip in item.ips" :key="ip" small class="mb-1">
							<span class="mx-2">{{ ip.split(':')[0] }} ({{ip.split(':')[1]}})</span>
<!--							<gb-flag-->
<!--									:code="ip.split(':')[1].toString().toLowerCase()"-->
<!--									size="mini"-->
<!--							/>-->
										<flag :iso="ip.split(':')[1].toString().toLowerCase()"/>
						</v-chip>
					</div>
				</template>
				<v-btn
						color="white"
						text
						small
						v-if="item['ips'].length === 0"
				>
					NO DATA
				</v-btn>
			</template>
      <template v-slot:[`item.country`]="{ item }">
        <span v-if="!item.country">
          Global
        </span>
        <span v-else>
          {{ item.country }}
          <flag :iso="item.country.toLowerCase()"/>
        </span>
      </template>
      <template v-slot:[`item.key_login`]="{ item }">
        <template v-if="item.key_expired === false">
          <v-btn
              text
              color="success"
              medium
              v-clipboard="item.key_login"
              v-if="item.key_status === 1"
              class="key"
          >
            <v-icon left>mdi-check</v-icon>
            {{item.key_login}}
            <v-icon right>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn
              text
              color="grey"
              medium
              v-if="item.key_status !== 1"
          >
            <v-icon left color="error">fas fa-ban</v-icon>
            <strike>{{item.key_login}}</strike>
            <v-icon right color="error">mdi-lock</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
              text
              color="brown"
              medium
          >
            <strike>{{item.key_login}}</strike>
            <v-icon right>mdi-update</v-icon>
          </v-btn>
        </template>
        <v-icon right :color="item.online > 0 ? 'green':'red'">mdi-lightning-bolt-circle</v-icon>
				<div>
					<v-btn value="justify" color="blue" dark small @click="doGameLogs(item.key_id)" class="mx-1" v-if="item.key_details.length > 0">
						<v-icon
								light
								left
						>
							mdi-dev-to
						</v-icon>
						Game logs
					</v-btn>
					<v-btn value="justify" color="success" dark small @click="doUpdateStatus(item.key_id,1)" v-if="item.key_status === 0" class="mx-1">
						<v-icon
								light
								left
						>
							mdi-lock-open
						</v-icon>
						{{ nn('__KEYUNLOCK__',lang) }}
					</v-btn>
					<v-btn value="justify"  color="warning" dark small @click="doUpdateStatus(item.key_id,0)" v-if="item.key_status === 1" class="mx-1">
						<v-icon
								light
								left
						>
							mdi-lock
						</v-icon>
						{{ nn('__KEYLOCK__',lang) }}
					</v-btn>
					<v-btn value="justify" color="success" dark small @click="doFreeze(item.key_id)" v-if="item.allow_renew === true && item.freeze === 0 && item.freezez === 0 && item.key_expired === false">
						<v-icon
							light
							left
						>
							mdi-lock-clock
						</v-icon>
						Freeze
					</v-btn>
					<v-btn value="justify" color="orange" dark small @click="doRestore(item.key_id)" v-if="item.freeze > 0">
						<v-icon
							light
							left
						>
							mdi-lock-open-check
						</v-icon>
						Restore Freeze
					</v-btn>
				</div>
        <div>
          <v-checkbox
            v-model="item.keyAutoRenew"
            @change="doUpdateAutoRenew(item)"
            :label="item.keyAutoRenew ? 'Auto Renew':'Auto Renew Disabled'"
          />
        </div>
      </template>
      <template v-slot:[`item.key_details`]="{ item }">
				<template v-if="item.freeze > 0">
					<v-row class="mt-1">
						<v-col cols="12">
							<v-chip
								color="green"
								small
							>
								<v-icon
									light
									left
								>
									mdi-clock
								</v-icon>
								KEY FREEZE: {{secondsToHms(item.freeze)}}
							</v-chip>
						</v-col>
					</v-row>
				</template>

				<v-row>
          <v-col cols=12>
            <v-btn
                white--text
                text
                small
            >
              <v-icon
                  light
                  left
              >
                mdi-check
              </v-icon>
              {{ nn('__CREATEDATE__',lang) }}: {{ doParseTime(item.key_createdat) }}
            </v-btn>
          </v-col>
          <template v-if="item.key_details.length === 0">
            <v-col cols=12>
              <v-btn
                  color="success"
                  text
                  small
              >
                <v-icon
                    light
                    left
                >
                  mdi-check
                </v-icon>
                {{ nn('__KEYUNSED__',lang) }}
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col cols=12>
              <v-btn
                  color="deep-orange darken-1"
                  text
                  small
              >
                <v-icon
                    light
                    left
                >
                  mdi-clock
                </v-icon>
                {{ nn('__EXPIREDATE__',lang) }}: {{ doParseTime(item.key_details[0])}}
              </v-btn>
            </v-col>
            <v-col cols=12>
              <v-btn
                  color="cyan darken-1"
                  text
                  small
              >
                <v-icon
                    light
                    left
                >
                  mdi-clock
                </v-icon>
                {{ nn('__FIRSTLOGIN__',lang) }}: {{ doParseTime(item.key_details[1])}}
              </v-btn>
            </v-col>
            <v-col cols=12>
              <v-btn
                  color="blue darken-1"
                  text
                  small
              >
                <v-icon
                    light
                    left
                >
                  mdi-clock
                </v-icon>
                {{ nn('__LASTLOGIN__',lang) }}: {{ doParseTime(item.key_details[2])}}
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-slot:[`item.key_package`]="{ item }">
        <v-btn
            text
            :color="PackageColors[item.key_package]"
            medium
        >
          {{item.key_package}}
          <v-icon right v-if="item.key_multihwid">mdi-monitor-multiple</v-icon>
          <v-icon right v-else>mdi-monitor</v-icon>
        </v-btn>
      </template>
			<template v-slot:[`item.key_status`]="{ item }">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="deep-orange darken-1"
							dark
							v-bind="attrs"
							v-on="on"
							small
						>
							{{ nn('__KEY_MAN__',lang) }}
							<v-icon
								light
								right
							>
								mdi-pencil
							</v-icon>
						</v-btn>
					</template>
					<v-list>
<!--						<v-list-item v-if="(item.key_status === 1 && item.key_details.length === 0 && item.key_package !== '2HOUR' && SellerStats.enableRefund)">-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify" color="cyan" text small @click="doRefund(item.key_id)" class="mx-1">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-cash-refund-->
<!--									</v-icon>-->
<!--									{{ nn('__KEYREFUND__',lang) }}-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->
<!--						<v-list-item v-if="(item.allow_renew === true && item.key_package !== '2HOUR')">-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify" color="cyan" text small @click="doRenew(item.key_id)">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-cash-minus-->
<!--									</v-icon>-->
<!--									{{ nn('__KEYRENEW__',lang) }}-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->
<!--						<v-list-item v-if="item.key_status === 0" >-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify" color="success" text small @click="doUpdateStatus(item.key_id,1)">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-lock-open-->
<!--									</v-icon>-->
<!--									{{ nn('__KEYUNLOCK__',lang) }}-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->

<!--						<v-list-item v-if="item.key_status === 1">-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify"  color="warning" text small @click="doUpdateStatus(item.key_id,0)">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-lock-->
<!--									</v-icon>-->
<!--									{{ nn('__KEYLOCK__',lang) }}-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->
						<v-list-item v-if="item.key_status !== 9 && item.key_details.length > 0">
							<v-list-item-title>
								<v-btn value="justify" color="orange" text small @click="toggleBlacklist(item)" class="mx-1">
									<v-icon
										light
										left
									>
										mdi-cancel
									</v-icon>
									Blacklist
								</v-btn>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="(item.allow_renew === true && item.key_package !== '2HOUR')">
							<v-list-item-title>
								<v-btn value="justify" color="cyan" text small @click="doRenew(item.key_id)">
									<v-icon
										light
										left
									>
										mdi-cash-minus
									</v-icon>
									{{ nn('__KEYRENEW__',lang) }}
								</v-btn>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="(item.key_status === 1 && item.key_details.length === 0 && item.key_package !== '2HOUR' && SellerStats.enableRefund)">
							<v-list-item-title>
								<v-btn value="justify" color="cyan" text small @click="doRefund(item.key_id)">
									<v-icon
										light
										left
									>
										mdi-cash-refund
									</v-icon>
									{{ nn('__KEYREFUND__',lang) }}
								</v-btn>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<v-btn value="justify" color="cyan darken-2" text small @click="doUpdateHwid(item)">
									<v-icon
										light
										left
									>
										mdi-lock-reset
									</v-icon>
									RESET HWID
								</v-btn>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="item['allow_regen'] === true">
							<v-list-item-title>
								<v-btn value="justify"  color="cyan" text small @click="doRegen(item.key_id)" class="mx-1">
									<v-icon
											light
											left
									>
										fas fa-greater-than-equal
									</v-icon>
									{{ nn('__KEYREGEN__',lang) }}
								</v-btn>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="item['key_expired'] === true">
							<v-list-item-title>
								<v-btn value="justify" color="red" text small @click="doRemoveKey(item.key_id)" class="mx-1">
									<v-icon
											light
											left
									>
										mdi-delete
									</v-icon>
									{{ nn('__KEYDELETE__',lang) }}
								</v-btn>
							</v-list-item-title>
						</v-list-item>
<!--						<v-list-item v-if="item.allow_renew === true && item.freeze === 0 && item.freezez === 0 && item.key_expired === false" >-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify" color="success" text small @click="doFreeze(item.key_id)">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-lock-clock-->
<!--									</v-icon>-->
<!--									Freeze-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->
<!--						<v-list-item v-if="item.freeze > 0" >-->
<!--							<v-list-item-title>-->
<!--								<v-btn value="justify" color="orange" text small @click="doRestore(item.key_id)">-->
<!--									<v-icon-->
<!--										light-->
<!--										left-->
<!--									>-->
<!--										mdi-lock-open-check-->
<!--									</v-icon>-->
<!--									Restore Freeze-->
<!--								</v-btn>-->
<!--							</v-list-item-title>-->
<!--						</v-list-item>-->
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
		<v-dialog
                v-model="BoxNewRegen"
                max-width="600px"
        >
            <v-card flat>
                <v-card-title>KEY DATA</v-card-title>
                <v-card-text>
                    <v-textarea
                            v-model="NewKeyData"
                            label="New Key"
                            readonly
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            @click="BoxNewRegen = false"
                            dark
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
		<v-overlay :value="Overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
		<GameLogs ref="GameLogs" />
		<Blacklist ref="Blacklist" :callback="fetchData" />
  </v-flex>
</template>

<script>
import languageHelper from '../helpers/language';
import {mapActions, mapState} from "vuex";
import {fetchData} from "../helpers";
import { clipboard } from 'vue-clipboards';
import Notice from "../helpers/notice";
const nn = languageHelper.nn
import GameLogs from '@/components/GameLogs'
import Blacklist from "@/components/Blacklist.vue";
export default {
  name: "Purchased",
  directives: { clipboard },
	components:{
		GameLogs,
		Blacklist,
	},
  data(){
    return {
      items: [],
      search: '',
      searchByShopName: 'All',
      searchByPackage: 'All',
      Overlay: false,
      PackageColors: {"1DAY":"cyan lighten-3","7DAY":"cyan lighten-2","15DAY":"cyan darken-2","30DAY":"cyan darken-4","2HOUR":"green darken-1"},
	  BoxNewRegen: false,
	  NewKeyData: null,
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('auth', ['status']),
    ...mapState('SellerStats', ['SellerStats']),
    ...mapState({
      alert: state => state.alert
    }),
    headers(){
      return [
        { text: nn('__DATAKEY__',this.lang), align: 'right', sortable: false,value: 'key_login',width:"auto" },
        { text: nn('__DATADETAILS__',this.lang), align: 'left', sortable: true,value: 'key_details',width:"auto"},
        { text: 'Country', align: 'center', sortable: false,value: 'country',width:"auto" },
				{ text: 'IP', align: 'left', sortable: false,value: 'ips',width:"auto" },
        { text: nn('__DATAPACKAGE__',this.lang), align: 'center', sortable: false,value: 'key_package',width:"auto" },
        { text: 'SHOP', align: 'center', sortable: false,value: 'shop_name',width:"auto" },
				{ text: nn('__DATAMODIFY__',this.lang), align: 'center', sortable: false,value: 'key_status',width: "auto" },
      ]
    },
    shopNames() {
      return ['All'].concat(this.items.map(item => item.shop_name))
    },
    Items(){
      const searchByShopName = this.searchByShopName
      return this.items.filter(item=>item.key_login.indexOf(this.search) > -1 || this.search === null || this.search === '').filter(item=>item.shop_name === searchByShopName || searchByShopName === 'All').filter(item=>item.key_package === this.searchByPackage || this.searchByPackage === 'All')
    },
    keyPackages(){
      return ['All'].concat(this.items.map(item => item.key_package))
    },
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    ...languageHelper,
		toggleBlacklist: function(item){
			this.$refs['Blacklist'].Show(item, 'shop');
		},
		doGameLogs: function(key_id){
			this.$refs['GameLogs'].FetchGameLogs(key_id, 'shop');
		},
		secondsToHms: function(d) {
			d = Number(d);
			let h = Math.floor(d / 3600)
			let m = Math.floor(d % 3600 / 60);
			let s = Math.floor(d % 3600 % 60);

			let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
			let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
			let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
			return hDisplay + mDisplay + sDisplay;
		},
    fetchData: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/shop/all`).then((resp)=>{
        this.items = resp;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
	doRegen: function(key_id){
		this.Overlay = true;
		this.$confirm(`Do you really want to re-gen key login ? This data can't restore and new key login not same as old format (Random new key login) !`,{ title: 'Warning' }).then(res => {
			if(res === true){
				this.NewKeyData = null;
				fetchData(`${this.$API}/api/shop/re-gen`,{ key_id }).then((json)=>{
					let message = (json.msg) ? json.msg:"Server Error !!!";
					if(json.success == true){
						this.success(message);
						Notice.success({message: nn(message, this.lang)});
						this.fetchData();
						this.NewKeyData = json.data;
						this.BoxNewRegen = true;
					}else{
						this.error(message);
					}
				},(err)=>{
					if(err === 401){
						this.logout();
					}else{
						this.error("SERVER ERROR !!!");
					}
				}).finally(()=>{
					this.Overlay = false;
				});
			}
		});
	},
		doRefund: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to refund ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/keys/refund`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
              Notice.success({message: nn(message, this.lang)});
							this.fetchData();
              let API = this.$API;
              this.$store.dispatch("SellerStats/updateData",{API});
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
		doCopyKey: function(item){
      if(item.key_status === 1){
        return item.key_login;
      }
    },
    doParseTime: function(data){
      const dt = new Date(data*1000);
      return dt.toLocaleString();
    },
		doUpdateStatusAllKey: function(status){
			this.Overlay = true;
			this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/status-all`,{ status }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
    doUpdateAutoRenew: function(key){
      this.Overlay = true;
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/shop/auto-renew`,{ key_id: key.key_id, key_status: key.keyAutoRenew }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success === true){
              this.success(message);
              Notice.success({message: nn(message, this.lang)});
            }else{
              key.keyAutoRenew = !key.keyAutoRenew;
              this.error(message);
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error("SERVER ERROR !!!");
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        }else{
          this.Overlay = false;
          key.keyAutoRenew = !key.keyAutoRenew;
        }
      });
    },
		doRemoveKey: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to do this action ? This can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/remove-key`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
              				Notice.success({message: nn(message, this.lang)});
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
		doUpdateStatus: function(key_id,key_status){
			this.Overlay = true;
			this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/status`,{ key_id,key_status }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
              Notice.success({message: nn(message, this.lang)});
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
		doRenew: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to renew ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/renew`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
              Notice.success({message: nn(message, this.lang)});
							this.fetchData();
              let API = this.$API;
              this.$store.dispatch("SellerStats/updateData",{API});
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
		doUpdateHwid: function(item){
			this.Overlay = true;
			const request = { key_id:item.key_id };
			fetchData(`${this.$API}/api/shop/reset_hwid`,request).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.success(message);
          Notice.success({message: nn(message, this.lang)});
					this.fetchData();
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error("SERVER ERROR !!!");
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
		doFreeze: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to freeze ? This data can't restore and only can freeze one time !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/freeze`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
		doRestore: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to restore freeze time ? This data can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/shop/restore`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
	},
  mounted(){
    this.fetchData();
  }
}
</script>

<style scoped>
.key{
  text-transform: none;
}
</style>
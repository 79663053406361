export const authService = {
    login,
    logout,
};
function login(apiUrl,username, password, googleAuth,hashsec) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, googleAuth,hashsec })
    };              
    return fetch(`${apiUrl}/auth`,requestOptions).then((response)=>{
        return response.json();
    }).then((json)=>{
        if(json.token){
            return Promise.resolve(json.token);
        }else if(json.qr && json.hashsec){
            return Promise.reject(json);
        }else{
            let error = (json.msg) ? json.msg:"Server Error !!!";
            return Promise.reject(error);
        }
    });
}
function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('Token');
}
<template>
  <div>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
        v-if="alert.message && alert.type === 'error'"
    >
      {{ nn(alert.message,lang) }}
    </v-alert>
    <template v-if="SellerStats['shop_seller'] !== false">
      <v-row>
        <v-col
          cols="auto"
        >
          <p class="mx-2">
            <v-text-field
              v-model="reqData['text']"
              :hint="formatDisplay"
              persistent-hint
              :label="nn('__KEYNAME__',lang)"
            />
          </p>
        </v-col>
        <v-col
            cols="auto"
        >
          <p class="mx-2">
            <v-select
                v-model="reqData['format']"
                :items="format"
                item-value="value"
                item-text="text"
                :label="nn('__KEYFORMAT__',lang)"
            />
          </p>
        </v-col>
        <v-col
            cols="auto"
        >
          <p class="mx-2">
            <v-checkbox
                v-model="reqData['shoppy']"
                :label="nn('__SHOPFORMAT__',lang)"
            />
          </p>
        </v-col>
        <v-col
            cols="auto"
        >
          <p class="mx-2">
            <v-checkbox
                v-model="is_limit_country"
                label="Limit Country ?"
            />
          </p>
        </v-col>
        <v-col
            cols="auto"
            v-if="is_limit_country"
        >
          <p class="mx-2">
            <v-autocomplete
                v-model="reqData['country']"
                :items="Countries"
                label="Country"
                required
                clearable
                rounded
                solo
                dark
            />
          </p>
        </v-col>
      </v-row>
      <v-divider />
      <br />
      <v-row>
        <v-col
          cols="auto"
          v-for="item in listSeller"
					:key="item.seller"
        >
          <v-card>
            <v-card-title>
              {{nn('__PURCHASE__',lang) }} {{nn('__KEY__',lang) }}: {{ item.seller }}
            </v-card-title>
            <v-card-text>
							<p class="block">
								<v-select
									v-model="package_key[item.seller]"
									:items="item.packages"
									item-value="value"
									item-text="text"
									:label="nn('__PACKAGECREATE__',lang)"
								/>
							</p>
							<p class="block">
								{{nn('__PRICE__',lang) }}: {{packagePriceBySeller(item.seller)}} <span v-if="reqData['country']">(Discount: {{packageDiscountBySeller(item.seller)}})</span>
							</p>
              <v-text-field
                v-model="number_key[item.seller]"
                :label="nn('__KEYS__',lang)"
                :hint="`${package_key[item.seller] === '2HOUR'?'TK':'$'} ${CalcPriceBySeller(item.seller)}`"
								:disabled="!package_key[item.seller]"
                persistent-hint
              />
              <p class="block" v-if="packageDiscountBySeller(item.seller) !== 'NONE'">
                Keys created with limited country will only work with the country you selected.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green"
                :disabled="CalcPriceBySeller(item.seller) <= 0 || (CalcPriceBySeller(item.seller) > SellerStats['balance'] && package_key[item.seller] !== '2HOUR') || (CalcPriceBySeller(item.seller) > SellerStats['testkeys'] && package_key[item.seller] === '2HOUR')"
                @click="doBuyNow(item.seller)"
              >
                {{nn('__BUY__',lang)}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-alert
          dense
          type="error"
      >
        <span class="text--h1">
          {{ nn('__ERROR_ANA__',lang) }}
        </span>
      </v-alert>
    </template>
    <v-dialog
      v-model="box"
      persistent
      transition="dialog-bottom-transition"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <template v-if="!keysData">
            {{ nn('__PROCESSING__',lang) }}....
          </template>
          <template v-else>
            {{ nn('__COMPLETED__',lang) }}
          </template>
        </v-card-title>
        <v-card-text>
          <v-alert
              dense
              :type="alert.type"
              timeout="5000"
              v-if="alert.message"
          >
            {{ nn(alert.message,lang) }}
          </v-alert>
          <br />
          <v-textarea
            v-model="keysData"
            v-if="keysData"
            label="KEY DATA"
          />
          <v-overlay :value="loader">
            <v-progress-circular
                indeterminate
                size="64"
            />
          </v-overlay>
        </v-card-text>
        <v-card-actions v-if="keysData">
          <v-btn
              @click="box=false"
          >
            {{ nn('__CLOSE__',lang) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import languageHelper from '../helpers/language';
import {mapActions, mapState} from "vuex";
import {fetchData} from "../helpers";
import CountryJson from '../json/countries.json';
const nn = languageHelper.nn
export default {
  name: "Shop",
  data(){
    return{
      nn,
      is_limit_country: false,
      loader: false,
      box: false,
      number:{
        "7DAY": 0,
        "30DAY": 0,
      },
      packages:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
      keysData: null,
      reqData: {
        text: 'NAME',
        format: 1,
        shoppy: false,
        country: "",
      },
      format: [
          { value:1,text:'KEY-XXXXXXXXXXXX'},
          { value:2,text:'KEY_XXXXXXXXXXXX'},
          { value:3,text:'KEYXXXXXXXXXXXX'},
      ],
      state: 1,
			number_key:{},
			package_key:{},
    }
  },
  computed: {
    ...mapState('SellerStats', ['SellerStats']),
    ...mapState('lang', ['lang']),
    ...mapState({
      alert: state => state.alert
    }),
    DiscountCountries(){
      return Object.keys(this.SellerStats['discounts']);
    },
    Countries(){
      return Object.keys(CountryJson).map((key) => {
        return {
          value: key,
          text: this.DiscountCountries.includes(key) ? `${CountryJson[key]} - Discount`:CountryJson[key]
        };
      });
    },
    formatDisplay(){
      const {format,text} = this.reqData;
      if(format === 1) return `${text}-XXXXXXXXXXXX`;
      if(format === 2) return `${text}_XXXXXXXXXXXX`;
      return `${text}XXXXXXXXXXXX`;
    },
		listSeller(){
			if(this.SellerStats['shop_seller'] === false) return [];
			return Object.keys(this.SellerStats['shop_seller']).map(key=>{
				const packageData = this.SellerStats['shop_seller'][key];
				let packages = [];
				if(packageData['7DAY'] > 0){
					packages.push({'text':`PACKAGE: 7DAY - $${packageData['7DAY']}`,'value':'7DAY'});
				}
				if(packageData['30DAY'] > 0){
					packages.push({'text':`PACKAGE: 30DAY - $${packageData['30DAY']}`,'value':'30DAY'});
				}
        if(packageData['2HOUR'] > 0){
          packages.push({'text':`PACKAGE: TEST KEY - ${packageData['2HOUR']} TK`,'value':'2HOUR'});
        }
				return {
					seller: key,
					packages,
					seller_id: packageData['seller_id'],
					'7DAY': packageData['7DAY'],
					'30DAY': packageData['30DAY'],
          '2HOUR': packageData['2HOUR'],
				}
			}).filter(v=>v.packages.length > 0)
		}
  },
  methods:{
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
		CalcPriceBySeller(seller){
			if(this.package_key[seller] && parseInt(this.number_key[seller]) > 0){
				const num_key = parseInt(this.number_key[seller]);
				const total_key = isNaN(num_key) ? 0 : num_key;
        let discountPrice = this.getPackageDiscountBySeller(seller);
        if(this.SellerStats['shop_seller'][seller][this.package_key[seller]] <= discountPrice){
          discountPrice = 0;
        }
				const price = this.SellerStats['shop_seller'][seller][this.package_key[seller]]-discountPrice;
				return total_key*price;
			}
			return 0;
		},
    packagePriceBySeller(seller){
      if(this.package_key[seller]){
        return `${this.package_key[seller] === '2HOUR' ? 'TK ':'$'}${this.SellerStats['shop_seller'][seller][this.package_key[seller]]}`
      }
      return 'NONE';
    },
		packageDiscountBySeller(seller){
      if(this.reqData.country) {
        const is_discountCountry = this.SellerStats['discounts'][this.reqData.country];
        if (this.package_key[seller] && this.package_key[seller] !== '2HOUR' && is_discountCountry.length > 0) {
          const index = this.packages.indexOf(this.package_key[seller]);
          return `$${is_discountCountry[index]}`
        }
      }
			return 'NONE';
		},
    getPackageDiscountBySeller(seller){
      if(this.reqData.country) {
        const is_discountCountry = this.SellerStats['discounts'][this.reqData.country];
        if (this.package_key[seller] && this.package_key[seller] !== '2HOUR' && (is_discountCountry && is_discountCountry.length > 0)) {
          const index = this.packages.indexOf(this.package_key[seller]);
          return is_discountCountry[index]
        }
      }
      return 0;
    },
    doBuyNow(seller){
      this.keysData = null;
      const keys = parseInt(this.number_key[seller]);
			const Package = this.package_key[seller];
			const seller_id = this.SellerStats['shop_seller'][seller]['seller_id'];
      if(!isNaN(keys) && keys > 0){
        this.box = true;
        this.loader = true;
        fetchData(`${this.$API}/api/shop/buy`,{ ...this.reqData, keys, package:Package, seller_id }).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
          if(json.success === true){
            this.success(message);
            this.keysData = json.data;
            let API = this.$API;
            this.$store.dispatch("SellerStats/updateData",{API});
          }else{
            this.error(message);
            this.box = false;
          }
        },(err)=>{
          if(err === 401){
            this.logout();
          }else{
            this.error("SERVER ERROR !!!");
            this.box = false;
          }
        }).finally(()=>{
          this.loader = false;
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
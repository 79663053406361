<template>
    <v-container fluid>
        <v-alert
                dense
                :type="alert.type"
                timeout="5000"
                v-if="alert.message">
            {{ nn(alert.message,lang) }}
        </v-alert>
        <v-layout row wrap>
            <template>
                <v-flex
                        sm=12
                        xs=12
                        md=12
                        lg=12
                >
                    <v-card>
                        <v-card-title>
                            <v-row>
                                <v-col cols=12 lg=2 xl=1>
                                    <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-width="600"
                                            offset-x
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                    color="green"
                                                    class="ma-2 white--text"
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                {{ nn('__CREATE__',lang) }}
                                                <v-icon right>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-card>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ nn('__CREATE__',lang) }} {{ nn('__KEYS__',lang) }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>

                                                <v-divider></v-divider>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            <v-text-field
                                                                    v-model="newKey.name"
                                                                    :label="nn('__KEYNAME__',lang)"
                                                                    placeholder="KEY NAME"
                                                                    :rules="[v => !!v || 'Required']"
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-select
                                                                    v-model="newKey.key_format"
                                                                    :items="keyFormatList"
                                                                    item-text="text"
                                                                    item-value="value"
                                                                    :label="nn('__KEYFORMAT__',lang)"
                                                                    :rules="[v => !!v || 'Required']"
                                                                    dense
                                                            ></v-select>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            <v-slider
                                                                    v-model="newKey.num"
                                                                    label="Num of key"
                                                                    append-icon="mdi-account-key"
                                                                    min=1
                                                                    max=100
                                                            >
                                                            <template v-slot:append>
                                                                <v-text-field
                                                                    v-model="newKey.num"
                                                                    type="number"
                                                                    class="mt-0 pt-0 text-center text-justify"
                                                                    outlined
                                                                    style="width: 60px"
                                                                />
                                                            </template>
                                                        </v-slider>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-switch
                                                                    v-model="newKey.packageonkey"
                                                                    color="purple"
                                                            ></v-switch>
                                                        </v-list-item-action>
                                                        <v-list-item-title>
                                                            {{nn('__PACKAGEONKEY__',lang)}}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-action>
                                                            <v-switch
                                                                    v-model="newKey.shoppy"
                                                                    color="orange"
                                                            ></v-switch>
                                                        </v-list-item-action>
                                                        <v-list-item-title>
                                                            {{nn('__SHOPFORMAT__',lang)}}
                                                        </v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item v-if="SellerStats.enableTest">
                                                        <v-list-item-action>
                                                            <v-switch
                                                                    v-model="isTestKey"
                                                                    color="blue"
                                                            ></v-switch>
                                                        </v-list-item-action>
                                                        <v-list-item-title>
                                                            {{nn('__CREATETESTKEY__',lang)}}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="!isTestKey">
                                                        <v-list-item-action>
                                                            <v-switch
                                                                    v-model="is_limit_country"
                                                                    color="blue"
                                                            />
                                                        </v-list-item-action>
                                                        <v-list-item-title>
                                                            Limit Country ?
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item v-if="is_limit_country && !isTestKey">
                                                        <v-list-item-title>
                                                            <v-autocomplete
                                                                    v-model="newKey['country']"
                                                                    :items="Countries"
                                                                    label="Country"
                                                                    required
                                                                    clearable
                                                                    rounded
                                                                    solo
                                                                    dark
                                                            />
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <template v-if="!isTestKey">
                                                        <v-list-item>
                                                            <v-list-item-action>
                                                                <v-select
                                                                        v-model="newKey.package"
                                                                        :items="packagesKey"
                                                                        item-text="text"
                                                                        item-value="value"
                                                                        :label="nn('__PACKAGECREATE__',lang)"
                                                                        dense
                                                                ></v-select>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </template>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            <template v-if="getPackageDiscount > 0">
                                                                Discount: {{ getPackageDiscount }}
                                                                <br />
                                                            </template>
                                                            <b>{{nn('__TOTALCREATE__',lang)}}</b> {{totalValue}} <span v-if="isTestKey"> / {{testkeys}} <strong>{{nn('__TEST_KEYS__',lang)}}</strong></span> <span v-else> / {{wallet}} <strong>{{nn('__BALANCE__',lang)}}</strong></span>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            <v-textarea
                                                                    v-if="listKeys"
                                                                    label="List Keys"
                                                                    :value="listKeys"
                                                                    hint="List Keys Created"
                                                                    readonly
                                                            ></v-textarea>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>

                                                    <v-btn
                                                            text
                                                            @click="menu = false"
                                                    >
                                                        {{ nn('__CANCEL__',lang) }}
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            outlined
                                                            @click="doAdd"
                                                            :disabled = "totalValueValid"
                                                            :loading = "Overlay"
                                                    >
                                                        {{ nn('__CREATE__',lang) }}  {{newKey.num}} {{ nn('__KEYS__',lang) }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-form>
                                    </v-menu>
                                </v-col>
								<v-col cols=12 lg=2 xl=2>
                                    <v-btn
                                            color="green"
                                            class="ma-2 white--text"
                                            @click="doToggleBoxCreateByPlan()"
                                    >
<!--                                        {{ nn('__CREATE__',lang) }}-->
                                        CREATE w PLAN
                                        <v-icon right>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
																																</v-col>
                                <v-col cols=12 lg=2 xl=1>
                                    <v-select
                                            v-model="showFilter.status"
                                            :items="FilterUsed"
                                            item-text="title"
                                            item-value="search"
                                            :hint="nn('__SEARCH_BY_STATUS__',lang)"
                                            persistent-hint
                                    />
                                </v-col>
                                <v-col cols=12 lg=2 xl=2>
                                    <v-select
                                            v-model="showFilter.key_package"
                                            :items="packages"
                                            item-text="title"
                                            item-value="search"
                                            :hint="nn('__SEARCH_BY_PACKAGE__',lang)"
                                            persistent-hint
                                    />
                                </v-col>
                                <v-col cols=12 lg=2 xl=2>
                                    <v-select
                                            v-model="showFilter.hwid"
                                            :items="FilterHWID"
                                            item-text="title"
                                            item-value="search"
                                            :hint="nn('__SEARCH_BY_HWID__',lang)"
                                            persistent-hint
                                    />
                                </v-col>
                                <v-col cols=12 lg=2 xl=2>
                                    <v-select
                                            v-model="showFilter.created"
                                            :items="FilterCreated"
                                            item-text="title"
                                            item-value="search"
                                            label="Show"
                                            :hint="nn('__SEARCH_BY_CREATED__',lang)"
                                            persistent-hint
                                    />
                                </v-col>
                                <v-col cols=12 lg=2 xl=2>
                                    <v-btn
                                            :loading="Overlay"
                                            :disabled="Overlay"
                                            color="blue lighten-3"
                                            class="ma-2 white--text"
                                            @click="doFilter"
                                    >
                                        {{ nn('__SEARCH__',lang) }}
                                        <v-icon
                                                right
                                                dark
                                        >
                                            mdi-crosshairs
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols=12 lg=3 xl=2>
                                    <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            :label="nn('__SEARCH__',lang)"
                                            single-line
                                            hide-details
                                    />
                                </v-col>
                                <v-col cols=12>
                                    <v-btn-toggle>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                        color="deep-orange darken-1"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                    {{ nn('__ALL_KEY_MAN__',lang) }}
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                                size="32px"
                                                                tile
                                                        >
                                                            <img
                                                                    src="/ban.png"
                                                                    alt="Remove All HWID"
                                                            >
                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title>
                                                        <v-btn
                                                                :loading="Overlay"
                                                                :disabled="Overlay"
                                                                color="amber darken-4"
                                                                class="ma-2 white--text"
                                                                small
                                                                @click="doUpdateHwid"
                                                        >
                                                            {{ nn('__RESETALLHWID__',lang) }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                                size="32px"
                                                                tile
                                                        >

                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title>
                                                        <v-btn
                                                                :loading="Overlay"
                                                                :disabled="Overlay"
                                                                color="amber lighten-2"
                                                                class="ma-2 white--text"
                                                                small
                                                                @click="doExportKeys"
                                                        >
                                                            {{ nn('__EXPORTUNUSED__',lang) }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                                size="32px"
                                                                tile
                                                        >

                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title>
                                                        <v-btn
                                                                :loading="Overlay"
                                                                :disabled="Overlay"
                                                                color="orange lighten-2"
                                                                class="ma-2 white--text"
                                                                small
                                                                @click="doUpdateStatus('all',0)"
                                                        >
                                                            {{ nn('__LOCKALLKEYS__',lang) }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                                size="32px"
                                                                tile
                                                        >

                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                    <v-list-item-title>
                                                        <v-btn
                                                                :loading="Overlay"
                                                                :disabled="Overlay"
                                                                color="green lighten-2"
                                                                class="ma-2 white--text"
                                                                small
                                                                @click="doUpdateStatus('all',1)"
                                                        >
                                                            {{ nn('__UNLOCKALLKEYS__',lang) }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn
                                                color="primary"
                                                dark
                                                text
                                        >
                                            {{ nn('__1HWID__',lang) }}
                                            <v-icon
                                                    color="primary"
                                                    dark
                                                    right
                                            >
                                                mdi-monitor
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                                color="primary"
                                                dark
                                                text
                                        >
                                            {{ nn('__2HWID__',lang) }}
                                            <v-icon
                                                    color="primary"
                                                    dark
                                                    right
                                            >
                                                mdi-monitor-multiple
                                            </v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-data-table
                                :headers="headers"
                                :items="items"
                                :search="search"
                                item-key="key_login"
                                :loading="Overlay"
                                loading-text="Loading... Please wait"
                                :footer-props="{itemsPerPageText: nn('__DATA_ROWS__',lang)}"
                                :items-per-page="20"
                        >
                            <template v-slot:top>
                            </template>
                            <template v-slot:[`item.ips`]="{ item }">
                                <template v-if="item.ips.length > 0">
                                    <div style="max-height: 220px; overflow-y: auto;">
                                        <v-chip v-for="ip in item.ips" :key="ip" small class="mb-1">
                                            <span class="mx-2">{{ ip.split(':')[0] }} ({{ip.split(':')[1]}})</span>
                                            <!--                          <gb-flag-->
                                            <!--                                  :code="ip.split(':')[1].toString().toLowerCase()"-->
                                            <!--                                  size="mini"-->
                                            <!--                          />-->
                                            <flag :iso="ip.split(':')[1].toString().toLowerCase()"/>
                                        </v-chip>
                                    </div>
                                </template>
                                <v-btn
                                        color="white"
                                        text
                                        small
                                        v-if="item['ips'].length === 0"
                                >
                                    NO DATA
                                </v-btn>
                            </template>
                            <template v-slot:[`item.country`]="{ item }">
            <span v-if="!item.country">
              Global
            </span>
                                <span v-else>
              {{ item.country }}
              <flag :iso="item.country.toLowerCase()"/>
            </span>
                            </template>
                            <template v-slot:[`item.key_details`]="{ item }">
                                <template v-if="item.freeze > 0">
                                    <v-row class="mt-1">
                                        <v-col cols="12">
                                            <v-chip
                                                    color="green"
                                                    small
                                            >
                                                <v-icon
                                                        light
                                                        left
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                KEY FREEZE: {{secondsToHms(item.freeze)}}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col cols=12>
                                        <v-btn
                                                white--text
                                                text
                                                small
                                        >
                                            <v-icon
                                                    light
                                                    left
                                            >
                                                mdi-check
                                            </v-icon>
                                            {{ nn('__PCINFO__',lang) }}: {{ item.pcinfor }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols=12>
                                        <v-btn
                                                white--text
                                                text
                                                small
                                        >
                                            <v-icon
                                                    light
                                                    left
                                            >
                                                mdi-check
                                            </v-icon>
                                            {{ nn('__CREATEDATE__',lang) }}: {{ doParseTime(item.key_createdat) }}
                                        </v-btn>
                                    </v-col>
                                    <template v-if="item.key_details.length === 0">
                                        <v-col cols=12>
                                            <v-btn
                                                    color="success"
                                                    text
                                                    small
                                            >
                                                <v-icon
                                                        light
                                                        left
                                                >
                                                    mdi-check
                                                </v-icon>
                                                {{ nn('__KEYUNSED__',lang) }}
                                            </v-btn>
                                        </v-col>
                                    </template>
                                    <template v-else>
                                        <v-col cols=12>
                                            <v-btn
                                                    color="deep-orange darken-1"
                                                    text
                                                    small
                                            >
                                                <v-icon
                                                        light
                                                        left
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ nn('__EXPIREDATE__',lang) }}: {{ doParseTime(item.key_details[0])}}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols=12>
                                            <v-btn
                                                    color="cyan darken-1"
                                                    text
                                                    small
                                            >
                                                <v-icon
                                                        light
                                                        left
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ nn('__FIRSTLOGIN__',lang) }}: {{ doParseTime(item.key_details[1])}}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols=12>
                                            <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    small
                                            >
                                                <v-icon
                                                        light
                                                        left
                                                >
                                                    mdi-clock
                                                </v-icon>
                                                {{ nn('__LASTLOGIN__',lang) }}: {{ doParseTime(item.key_details[2])}}
                                            </v-btn>
                                        </v-col>
                                    </template>
                                </v-row>
                            </template>
                            <template v-slot:[`item.key_login`]="{ item }">
                                <template v-if="item.key_expired === false">
                                    <v-btn
                                            text
                                            color="success"
                                            medium
                                            v-clipboard="item.key_login"
                                            v-if="item.key_status === 1"
                                            class="key"
                                    >
                                        <v-icon left>mdi-check</v-icon>
                                        {{item.key_login}}
                                        <v-icon right>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn
                                            text
                                            color="grey"
                                            medium
                                            v-if="item.key_status !== 1"
                                    >
                                        <v-icon left color="error">fas fa-ban</v-icon>
                                        <strike>{{item.key_login}}</strike>
                                        <v-icon right color="error">mdi-lock</v-icon>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <v-btn
                                            text
                                            color="brown"
                                            medium
                                    >
                                        <strike>{{item.key_login}}</strike>
                                        <v-icon right>mdi-update</v-icon>
                                    </v-btn>
                                </template>
                                <template v-if="item.key_oldlogin != '' && item.key_oldlogin != null">
                                    <p><v-btn
                                            text
                                            color="warning"
                                            medium
                                    >
                                        OLD: {{item.key_oldlogin}}
                                    </v-btn></p>
                                </template>
                                <v-icon right :color="item.online > 0 ? 'green':'red'">mdi-lightning-bolt-circle</v-icon>
                                <div>
                                    <v-btn value="justify" color="blue" dark small @click="doGameLogs(item.key_id)" class="mx-1" v-if="item.key_details.length > 0">
                                        <v-icon
                                                light
                                                left
                                        >
                                            mdi-dev-to
                                        </v-icon>
                                        Game logs
                                    </v-btn>
                                    <v-btn value="justify" color="success" dark small @click="doUpdateStatus(item.key_id,1)" v-if="item.key_status === 0" class="mx-1">
                                        <v-icon
                                                light
                                                left
                                        >
                                            mdi-lock-open
                                        </v-icon>
                                        {{ nn('__KEYUNLOCK__',lang) }}
                                    </v-btn>
                                    <v-btn value="justify"  color="warning" dark small @click="doUpdateStatus(item.key_id,0)" v-if="item.key_status === 1" class="mx-1">
                                        <v-icon
                                                light
                                                left
                                        >
                                            mdi-lock
                                        </v-icon>
                                        {{ nn('__KEYLOCK__',lang) }}
                                    </v-btn>

                                    <v-btn value="justify" color="success" dark small @click="doFreeze(item.key_id)" v-if="item.allow_renew === true && item.freeze === 0 && item.freezez === 0 && item.key_expired === false">
                                        <v-icon
                                                light
                                                left
                                        >
                                            mdi-lock-clock
                                        </v-icon>
                                        Freeze
                                    </v-btn>
                                    <v-btn value="justify" color="orange" dark small @click="doRestore(item.key_id)" v-if="item.freeze > 0">
                                        <v-icon
                                                light
                                                left
                                        >
                                            mdi-lock-open-check
                                        </v-icon>
                                        Restore Freeze
                                    </v-btn>
                                </div>
                                <div>
                                  <v-checkbox
                                   v-model="item.keyAutoRenew"
                                   @change="doUpdateAutoRenew(item)"
                                   :label="item.keyAutoRenew ? 'Auto Renew':'Auto Renew Disabled'"
                                  />
                                </div>
                            </template>
                            <template v-slot:[`item.key_hwid`]="{ item }">
                                <v-select
                                        v-model="item.key_hwid"
                                        :items="item.key_hwid"
                                        :label="nn('__LISTHWID__',lang)"
                                        multiple
                                        @change="doUpdateHwid(item)"
                                        v-if="item.key_hwid.length > 0"
                                ></v-select>
                                <v-btn
                                        color="green"
                                        text
                                        x-small
                                        v-if="item.key_hwid.length === 0"
                                >
                                    <v-icon
                                            light
                                            left
                                    >
                                        mdi-check
                                    </v-icon>
                                    {{ nn('__NO_HWID__',lang) }}
                                </v-btn>
                                <p v-if="item['hwid_reset'] > 0" class="orange--text">
                                    HWID RESET: {{ item['hwid_reset'] }}
                                </p>
                            </template>
                            <template v-slot:[`item.key_package`]="{ item }">
                                <v-btn
                                        text
                                        :color="PackageColors[item.key_package]"
                                        medium
                                >
                                    {{item.key_package}}
                                    <v-icon right v-if="item.key_multihwid">mdi-monitor-multiple</v-icon>
                                    <v-icon right v-else>mdi-monitor</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:[`item.key_status`]="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                color="deep-orange darken-1"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                        >
                                            {{ nn('__KEY_MAN__',lang) }}
                                            <v-icon
                                                    light
                                                    right
                                            >
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-if="item.key_status !== 9 && item.key_details.length > 0">
                                            <v-list-item-title>
                                                <v-btn value="justify" color="orange" dark small @click="toggleBlacklist(item)" class="mx-1">
                                                    <v-icon
                                                            light
                                                            left
                                                    >
                                                        mdi-cancel
                                                    </v-icon>
                                                    Blacklist
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="item['key_expired'] === true">
                                            <v-list-item-title>
                                                <v-btn value="justify" color="error" text small @click="doRemoveKey(item.key_id)">
                                                    <v-icon
                                                            light
                                                            left
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                    {{ nn('__KEYDELETE__',lang) }}
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="(item.allow_renew === true && item.key_package !== '2HOUR')">
                                            <v-list-item-title>
                                                <v-btn value="justify" color="cyan" text small @click="doRenew(item.key_id)">
                                                    <v-icon
                                                            light
                                                            left
                                                    >
                                                        mdi-cash-minus
                                                    </v-icon>
                                                    {{ nn('__KEYRENEW__',lang) }}
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="item['allow_regen'] === true">
                                            <v-list-item-title>
                                                <v-btn value="justify"  color="cyan" text small @click="doRegen(item.key_id)" class="mx-1">
                                                    <v-icon
                                                            light
                                                            left
                                                    >
                                                        fas fa-greater-than-equal
                                                    </v-icon>
                                                    {{ nn('__KEYREGEN__',lang) }}
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="(item.key_status === 1 && item.key_details.length === 0 && item.key_package !== '2HOUR' && SellerStats.enableRefund)">
                                            <v-list-item-title>
                                                <v-btn value="justify" color="cyan" dark small @click="doRefund(item.key_id)" class="mx-1">
                                                    <v-icon
                                                            light
                                                            left
                                                    >
                                                        mdi-cash-refund
                                                    </v-icon>
                                                    {{ nn('__KEYREFUND__',lang) }}
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <!--								<v-list-item v-if="item.allow_renew === true && item.freeze === 0 && item.freezez === 0 && item.key_expired === false" >-->
                                        <!--									<v-list-item-title>-->
                                        <!--										<v-btn value="justify" color="success" text small @click="doFreeze(item.key_id)">-->
                                        <!--											<v-icon-->
                                        <!--												light-->
                                        <!--												left-->
                                        <!--											>-->
                                        <!--												mdi-lock-clock-->
                                        <!--											</v-icon>-->
                                        <!--											Freeze-->
                                        <!--										</v-btn>-->
                                        <!--									</v-list-item-title>-->
                                        <!--								</v-list-item>-->
                                        <!--								<v-list-item v-if="item.freeze > 0" >-->
                                        <!--									<v-list-item-title>-->
                                        <!--										<v-btn value="justify" color="orange" text small @click="doRestore(item.key_id)">-->
                                        <!--											<v-icon-->
                                        <!--												light-->
                                        <!--												left-->
                                        <!--											>-->
                                        <!--												mdi-lock-open-check-->
                                        <!--											</v-icon>-->
                                        <!--											Restore Freeze-->
                                        <!--										</v-btn>-->
                                        <!--									</v-list-item-title>-->
                                        <!--								</v-list-item>-->
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex>
            </template>
        </v-layout>
        <v-dialog
                v-model="BoxNewRegen"
                max-width="600px"
        >
            <v-card flat>
                <v-card-title>KEY DATA</v-card-title>
                <v-card-text>
                    <v-textarea
                            v-model="NewKeyData"
                            label="New Key"
                            readonly
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            @click="BoxNewRegen = false"
                            dark
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="showNews"
        >
            <v-card>
                <v-toolbar
                        color="green"
                        dark
                >
                    <v-toolbar-title><v-icon left>fas fa-volume-up</v-icon>NEWS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="doCloseNews"><v-icon>fas fa-times</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <div class="text-h6 pa-12" v-html="SellerStats.news"></div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                            text
                            @click="doCloseNews"
                    >Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="Overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <GameLogs ref="GameLogs" />
        <Blacklist ref="Blacklist" :callback="fetchData" />
								<v-dialog
												v-model="boxCreateByPlan"
												persistent
												max-width="900px"
								>
									<v-card>
													<v-card-title>
																	PURCHASE KEY
													</v-card-title>
													<v-card-text>
																	<v-row>
																					<v-col cols="12">
																									<v-select
																													v-model="createDataByPlan.planId"
																													label="Plan"
																													:items="SellerStats['plans']"
																													:rules="[(v) => !!v || 'Plan is required']"
																													item-value="planId"
																													item-text="planName"
																													placeholder="Select Plan"
																													persistent-hint
																									/>
																					</v-col>
																					<v-col cols="12" v-if="createDataByPlan.planId">
																									<v-list>
																													<v-list-item>
																																	<v-list-item-content>
																																					<v-list-item-title class="orange--text">
																																									Price: ${{ Plan['planPrice'] }}
																																					</v-list-item-title>
																																	</v-list-item-content>
																													</v-list-item>
                             <v-list-item>
                                 <v-list-item-content>
                                     <v-list-item-title class="blue--text">
                                         Limit Country:
																																									<template v-if="Plan['planCountry'] === 'Global'">
																																													<v-icon color="red">
																																																	mdi-cancel
																																													</v-icon>
																																									</template>
<!--                                         <gb-flag-->
<!--																																																	:code="Plan['planCountry'].toLowerCase()"-->
<!--																																																	size="mini"-->
<!--																																																	v-else-->
<!--                                         />-->
                                         <flag :iso="Plan['planCountry'].toLowerCase()"/>
                                     </v-list-item-title>
                                 </v-list-item-content>
                             </v-list-item>
                             <v-list-item>
                                 <v-list-item-content>
                                     <v-list-item-title class="green--text">
                                         HWID Limit:
																																									<template  v-if="Plan['planMultiHWID']">
                                             <v-icon class="mx-1">
                                                 mdi-monitor-multiple
                                             </v-icon>
                                             2 HWID
                                         </template>
                                         <template v-else>
                                             <v-icon class="mx-1">
                                                 mdi-monitor
                                             </v-icon>
                                             1 HWID
                                         </template>
                                     </v-list-item-title>
                                 </v-list-item-content>
                             </v-list-item>
																									</v-list>
																					</v-col>
																					<v-col
																									cols="12"
																					>
																						<v-text-field
																										v-model="createDataByPlan.prefix"
																										label="Prefix of Key"
																										:rules="[(v) => !!v || 'Prefix is required']"
																										:hint="AutoPrefixKey"
																										placeholder="MOKKA"
																										persistent-hint
																										clearable
                          :disabled="!createDataByPlan.planId || AutoMaxKey <= 0"
																						/>
																					</v-col>
                     <v-col
																						cols="12"
                     >
                         <v-select
																										v-model="createDataByPlan.format"
																										:label="nn('__KEYFORMAT__',lang)"
																										:items="keyFormatList"
																										:disabled="!createDataByPlan.planId || AutoMaxKey <= 0"
																									/>
                     </v-col>
                    <v-col cols="12">

                        <v-slider
                            v-model="createDataByPlan.keys"
                            label="Keys"
                            prepend-icon="mdi-account-key"
                            :min="AutoMaxKey > 0 ? 1 : 0"
                            :max="AutoMaxKey"
                            thumb-label="always"
                            :disabled="!createDataByPlan.planId || AutoMaxKey <= 0"
                        >
                            <template v-slot:append>
                                            <v-text-field
                                                            v-model="createDataByPlan.keys"
                            type="number"
                                                            class="mt-0 pt-0 text-center text-justify"
                                                            outlined
                                                            style="width: 60px"
                                            />
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox
                                        v-model="createDataByPlan.pok"
                                        label="Package on Key"
                                        :disabled="!createDataByPlan.planId || AutoMaxKey <= 0"
                        />
                    </v-col>
                     <v-col cols="6">
                         <v-checkbox
                          v-model="createDataByPlan.shoppy"
                          :label="nn('__SHOPFORMAT__',lang)"
                          :disabled="!createDataByPlan.planId || AutoMaxKey <= 0"
                         />
                     </v-col>
																	</v-row>

                 <v-overlay :value="keyCreating">
                     <v-progress-circular
                      indeterminate
                      size="64"
                     />
                 </v-overlay>
             </v-card-text>
             <v-card-text v-if="keys_created.length > 0">
                 <v-textarea
                  :value="keys_created.join(createDataByPlan.shoppy ? ',\n':'\n')"
                  label="Keys"
                  outlined
                  readonly
                 />
             </v-card-text>
            <v-card-actions>
                 <v-btn
                     color="green"
                     :disabled="keyCreating || !createDataByPlan.planId || AutoMaxKey <= 0 || !createDataByPlan.prefix || !createDataByPlan.format || createDataByPlan.keys <= 0 || createDataByPlan.keys > AutoMaxKey"
                     @click="doCreateKey"
                     :loading="keyCreating"
                 >
                     {{ nn('__CREATE__',lang) }}
                 </v-btn>
                <v-spacer />
																	<v-btn
																					color="blue darken-1"
																					@click="boxCreateByPlan = false"
                     :disabled="keyCreating"
																	>
																					Close
																	</v-btn>
													</v-card-actions>
									</v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { fetchData } from '../helpers';
import languageHelper from '../helpers/language';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import CountryJson from "../json/countries.json";
import Notice from '../helpers/notice';
const nn = languageHelper.nn
import GameLogs from "@/components/GameLogs.vue";
import Blacklist from "@/components/Blacklist.vue";
export default {
    name: 'VipKey',
    directives: { clipboard },
    components: {
        GameLogs,
        Blacklist,
    },
    computed: {
        ...mapState('lang', ['lang']),
        ...mapState('auth', ['status']),
        ...mapState('SellerStats', ['SellerStats']),
        ...mapState({
            alert: state => state.alert
        }),
        AutoMaxKey(){
          const {planPrice} = this.Plan;
          const {balance} = this.SellerStats;
          if(!planPrice){
              return 0;
          }
          return Math.floor(balance / planPrice);
        },
								Plan(){
          const plans = this.SellerStats['plans'].filter((plan) =>plan.planId === this.createDataByPlan.planId);
          if(plans.length > 0){
														return plans[0];
										}
          return {};
								},
								AutoFormatKey(){
          switch(this.createDataByPlan.format){
              case 1:
																		return '_';
														case 2:
																		return '-';
														default: return '';
										}
								},
								AutoPackageName(){
												const plan = this.Plan;
												return plan ? plan['planName'].replace(' ','-') : '';
								},
								AutoPrefixKey(){
            const prefix = this.createDataByPlan.prefix ? this.createDataByPlan.prefix : 'MOKKA';
            const pok = this.createDataByPlan.pok ? this.AutoPackageName : 0;
            return `${prefix}${this.AutoFormatKey}${pok}XXXXXXXXXXXXXX`;
								},
        DiscountCountries(){
            return Object.keys(this.SellerStats['discounts']);
        },
        Countries(){
            return Object.keys(CountryJson).map((key) => {
                return {
                    value: key,
                    text: this.DiscountCountries.includes(key) ? `${CountryJson[key]} - Discount`:CountryJson[key]
                };
            });
        },
        FilterCreated(){
            return [
                {title:nn('__ALL__',this.lang),search:null},
                {title:nn('__SEARCHKEYS_1DAY__',this.lang),search:86400},
                {title:nn('__SEARCHKEYS_1WEEK__',this.lang),search:604800}]
        },
        FilterHWID(){
            return [
                {title:nn('__ALL__',this.lang),search:null},
                {title:nn('__SEARCHHWID_SINGLE__',this.lang),search:false},
                {title:nn('__SEARCHHWID_MULTI__',this.lang),search:true}
            ]
        },
        FilterUsed(){
            return [
                {title:nn('__ALL__',this.lang),search:undefined},
                {title:nn('__SEARCHSTATUS_UNUSED__',this.lang),search:1},
                {title:nn('__SEARCHSTATUS_USED__',this.lang),search:2},
                {title:nn('__SEARCHSTATUS_EXPIRED__',this.lang),search:3},
                {title:nn('__SEARCHSTATUS_REGENERATED__',this.lang),search:6}
            ]
        },
        packages(){
            return [
                {title:nn('__ALL__',this.lang), search:null},
                {title:nn('__SEARCHPACKAGE_2HOUR__',this.lang), search:'2HOUR'},
                {title:nn('__SEARCHPACKAGE_1DAY__',this.lang), search:'1DAY'},
                {title:nn('__SEARCHPACKAGE_7DAY__',this.lang), search:'7DAY'},
                {title:nn('__SEARCHPACKAGE_15DAY__',this.lang), search:'15DAY'},
                {title:nn('__SEARCHPACKAGE_30DAY__',this.lang), search:'30DAY'}
            ]
        },
        headers(){
            return [
                { text: nn('__DATAKEY__',this.lang), align: 'right', sortable: false,value: 'key_login',width:"auto" },
                { text: nn('__DATADETAILS__',this.lang), align: 'left', sortable: true,value: 'key_details',width:"auto"},
                { text: nn('__DATAHWID__',this.lang), align: 'left', sortable: true,value: 'key_hwid',width:"auto" },
                { text: 'IP', align: 'left', sortable: false,value: 'ips',width:"auto" },
                { text: nn('__DATAPACKAGE__',this.lang), align: 'center', sortable: false,value: 'key_package',width:"auto" },
                { text: 'Country', align: 'center', sortable: false,value: 'country',width:"auto" },
                { text: nn('__DATAPLAYEDTIME__',this.lang), align: 'center', sortable: false,value: 'playedTime',width:"auto" },
                { text: nn('__DATAMODIFY__',this.lang), align: 'center', sortable: false,value: 'key_status',width: "auto" },
            ]
        },
        wallet(){
            return this.$store.state.SellerStats.SellerStats.balance;
        },
        testkeys(){
            return this.$store.state.SellerStats.SellerStats.testkeys;
        },
        packagesKey(){
            const price = this.$store.state.SellerStats.SellerStats.price;
            let newPackage = [];
            price.forEach((element,index) => {
                if(element > 0)
                {
                    newPackage.push({value:index,text:`${this.packageList[index]} - ${element}`});
                }
            });
            return newPackage;
        },
        getPackageDiscount(){
            if(this.newKey.country) {
                const is_discountCountry = this.SellerStats['discounts'][this.newKey.country];
                if (this.newKey.package && !this.isTestKey && (is_discountCountry && is_discountCountry.length > 0)) {
                    return is_discountCountry[this.newKey.package]
                }
            }
            return 0;
        },
        totalValue(){
            const keys = this.newKey.num;
            const priceSeller = this.$store.state.SellerStats.SellerStats.price;
            const price = (this.isTestKey) ? 1:priceSeller[this.newKey.package];
            const discount_price = (this.getPackageDiscount > price) ? 0:this.getPackageDiscount;
            const final_price = price-discount_price;
            let totalValue = keys*final_price;
            return totalValue;
        },
        totalValueValid(){
            const keys = this.newKey.num;
            const priceSeller = this.$store.state.SellerStats.SellerStats.price;
            const price = (this.isTestKey) ? 1:priceSeller[this.newKey.package];
            const discount_price = (this.getPackageDiscount > price) ? 0:this.getPackageDiscount;
            const final_price = price-discount_price;
            let totalValue = keys*final_price;
            let valid = ((this.isTestKey) ? (totalValue > this.testkeys):(totalValue > this.wallet)) || final_price < 0;
            return valid;
        }
    },
    methods: {
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        ...languageHelper,
        doCreateKey(){
            this.keyCreating = true;
            this.keys_created = [];
            fetchData(`${this.$API}/api/keys/create`,this.createDataByPlan).then((resp)=>{
                if(resp.success){
                    if(resp.data) {
                        this.keys_created = resp.data;
                    }
                    const message = resp.msg ? this.nn(resp.msg, this.lang) : 'KEYS CREATED';
                    this.success(message);
                    Notice.success({message});
                    this.fetchData();
                }else{
                    const message = resp.msg ? this.nn(resp.msg, this.lang) : 'SERVER ERROR';
                    Notice.error({message});
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.keyCreating = false;
            });
        },
        toggleBlacklist: function(item){
            this.$refs['Blacklist'].Show(item, 'keys');
        },
        doGameLogs: function(key_id){
            this.$refs['GameLogs'].FetchGameLogs(key_id, 'keys');
        },
        secondsToHms: function(d) {
            d = Number(d);
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay;
        },
        doCloseNews(){
            this.showNews=false;
            this.$cookies.set('read_news',Math.floor(Date.now() / 1000));
        },
        filterData(){
            const filter = this.showFilter;
            const cleanFilter = this.clean(filter);
            const {username,status,key_package,created,hwid} = cleanFilter;
            return {username,status,key_package,created,hwid};
        },
        fetchData: function(request){
            this.Overlay = true;
            fetchData(`${this.$API}/api/keys/all`,request).then((resp)=>{
                this.items = resp;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                if(!this.showFilter.key_package) this.showFilter.key_package = null
                if(!this.showFilter.created) this.showFilter.created = null
                if(!this.showFilter.hwid) this.showFilter.hwid = null
                this.Overlay = false;
            });
        },
        clean(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
                    delete obj[propName];
                }
            }
            return obj
        },
        doUpdateHwid: function(item){
            this.Overlay = true;
            let request = {};
            if(item){
                const { key_id, key_hwid } = item;
                request = { key_id, key_hwid };
            }else{
                const key_hwid = [];
                request = { key_hwid };
            }
            const filted = this.filterData();
            fetchData(`${this.$API}/api/keys/hwid`,request).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData(filted);
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error("SERVER ERROR !!!");
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        doCopyKey: function(item){
            if(item.key_status === 1){
                return item.key_login;
            }
        },
        doParseTime: function(data){
            var dt = new Date(data*1000);
            return dt.toLocaleString();
        },
        doFilter: function(){
            this.fetchData(this.filterData());
        },
        doUpdateStatus: function(key_id,key_status){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/status`,{ key_id,key_status }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            Notice.success({message: nn(message, this.lang)});
                            this.fetchData(filted);
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }else{
                    this.Overlay = false;
                }
            });
        },
        // doDelete: function(key_id){
        //     this.Overlay = true;
        //     const filted = this.filterData();
        //     this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        //         if(res === true){
        //             fetchData(`${this.$API}/api/keys/delete`,{ key_id },'DELETE').then((json)=>{
        //                 let message = (json.msg) ? json.msg:"Server Error !!!";
        //                 if(json.success == true){
        //                     this.success(message);
        //                     Notice.success({message: nn(message, this.lang)});
        //                     this.fetchData(filted);
        //                 }else{
        //                     this.error(message);
        //                 }
        //             },(err)=>{
        //                 if(err === 401){
        //                     this.logout();
        //                 }else{
        //                     this.error("SERVER ERROR !!!");
        //                 }
        //             }).finally(()=>{
        //                 this.Overlay = false;
        //             });
        //         }else{
        //             this.Overlay = false;
        //         }
        //     });
        // },
        doRemoveKey: function(key_id){
			this.Overlay = true;
			this.$confirm(`Do you really want to do this action ? This can't restore !`,{ title: 'Warning' }).then(res => {
				if(res === true){
					fetchData(`${this.$API}/api/keys/remove-key`,{ key_id }).then((json)=>{
						let message = (json.msg) ? json.msg:"Server Error !!!";
						if(json.success == true){
							this.success(message);
              				Notice.success({message: nn(message, this.lang)});
							this.fetchData();
						}else{
							this.error(message);
						}
					},(err)=>{
						if(err === 401){
							this.logout();
						}else{
							this.error("SERVER ERROR !!!");
						}
					}).finally(()=>{
						this.Overlay = false;
					});
				}else{
					this.Overlay = false;
				}
			});
		},
        doRegen: function(key_id){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to re-gen key login ? This data can't restore and new key login not same as old format (Random new key login) !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/re-gen`,{ key_id }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            Notice.success({message: nn(message, this.lang)});
                            this.fetchData(filted);
                            this.NewKeyData = json.data;
                            this.BoxNewRegen = true;
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }
            });
        },
        doExportKeys: function(){
            this.Overlay = true;
            fetch(`${this.$API}/api/keys/export`,{method:'GET',headers:{'Authorization': localStorage.getItem('Token')}}).then(async(resp)=>{
                if(resp.status === 401){
                    this.logout();
                }else if(resp.status !== 200){
                    this.error("SERVER ERROR !!!");
                }else{
                    let data = await resp.blob();
                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(data);
                    a.href = url;
                    a.download = 'export.txt';
                    document.body.append(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 3000);
                }
            },()=>{
                this.error("SERVER ERROR !!!");
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        doAdd: function(){
            this.Overlay = true;
            if(this.$refs.form.validate() === false){
                this.Overlay = false;
                return;
            }
            const filted = this.filterData();
            let requestData = Object.assign({},this.newKey);
            requestData.package = (this.isTestKey) ? -1:requestData.package;
            requestData.country = (this.isTestKey) ? '':requestData.country;
            fetchData(`${this.$API}/api/keys/add`,requestData).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true && json.data != null){
                    this.listKeys = json.data;
                    this.success(message);
                    this.fetchData(filted);
                    let API = this.$API;
                    this.$store.dispatch("SellerStats/updateData",{API});
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;

            });
        },
        doRefund: function(key_id){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to refund ? This data can't restore !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/refund`,{ key_id }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            Notice.success({message: nn(message, this.lang)});
                            this.fetchData(filted);
                            let API = this.$API;
                            this.$store.dispatch("SellerStats/updateData",{API});
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }else{
                    this.Overlay = false;
                }
            });
        },
      doUpdateAutoRenew: function(key){
        this.Overlay = true;
        this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
          if(res === true){
            fetchData(`${this.$API}/api/keys/auto-renew`,{ key_id: key.key_id, key_status: key.keyAutoRenew }).then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success === true){
                this.success(message);
                Notice.success({message: nn(message, this.lang)});
              }else{
                key.keyAutoRenew = !key.keyAutoRenew;
                this.error(message);
              }
            },(err)=>{
              if(err === 401){
                this.logout();
              }else{
                this.error("SERVER ERROR !!!");
              }
            }).finally(()=>{
              this.Overlay = false;
            });
          }else{
            this.Overlay = false;
            key.keyAutoRenew = !key.keyAutoRenew;
          }
        });
      },
      doRenew: function(key_id){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to renew ? This data can't restore !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/renew`,{ key_id }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            Notice.success({message: nn(message, this.lang)});
                            this.fetchData(filted);
                            let API = this.$API;
                            this.$store.dispatch("SellerStats/updateData",{API});
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }else{
                    this.Overlay = false;
                }
            });
        },
        doFreeze: function(key_id){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to freeze ? This data can't restore and only can freeze one time !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/freeze`,{ key_id }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            this.fetchData(filted);
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }else{
                    this.Overlay = false;
                }
            });
        },
        doRestore: function(key_id){
            this.Overlay = true;
            const filted = this.filterData();
            this.$confirm(`Do you really want to restore freeze time ? This data can't restore !`,{ title: 'Warning' }).then(res => {
                if(res === true){
                    fetchData(`${this.$API}/api/keys/restore`,{ key_id }).then((json)=>{
                        let message = (json.msg) ? json.msg:"Server Error !!!";
                        if(json.success == true){
                            this.success(message);
                            this.fetchData(filted);
                        }else{
                            this.error(message);
                        }
                    },(err)=>{
                        if(err === 401){
                            this.logout();
                        }else{
                            this.error("SERVER ERROR !!!");
                        }
                    }).finally(()=>{
                        this.Overlay = false;
                    });
                }else{
                    this.Overlay = false;
                }
            });
        },
        totalKeys: function(){
            return "Num of keys: "+this.newKey.num;
        },
								doToggleBoxCreateByPlan: function(){
            this.keys_created = [];
												this.createDataByPlan = {
																prefix: '',
																format: 2,
																planId: '',
																keys: 0,
																pok: false,
																shoppy: false,
												};
												this.boxCreateByPlan = true;
								},
    },
    data : () => ({
        keys_created: [],
        keyCreating: false,
								boxCreateByPlan: false,
								createDataByPlan:{
            prefix: '',
            format: 2,
            planId: null,
            keys: 0,
            pok: false,
            shoppy: false,
        },
        packages_name:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
        BoxNewRegen: false,
        NewKeyData: null,
        listKeys: "",
        isTestKey: false,
        is_limit_country: false,
        menu: false,
        valid: false,
        showFilter: {
            status: 1,
            key_package: null,
            created: null,
            hwid: null,
            key_format: 1
        },
        newKey:{
            num: 1,
            package: 1,
            country: "",
        },
        extraTimeAll:true,
        addExtraMenu: false,
        extraTimeKey: "",
        extraTime: 0,
        extraTimeExpired: false,
        modifyMode: false,
        Overlay: false,
        dialog: false,
        search: '',
        items: [],
        sellers: ["All"],
        updateMode: false,
        packageList: ['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
        keyFormatList: [{value:1,text:'Name_'},{value:2,text:'Name-'},{value:3,text:'Name'}],
        //packagesKey:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
        topupSellerData: {},
        topupMethod: "",
        PackageColors: {"1DAY":"cyan lighten-3","7DAY":"cyan lighten-2","15DAY":"cyan darken-2","30DAY":"cyan darken-4","2HOUR":"green darken-1"},
        showNews:false,
    }),
    watch:{
        SellerStats:function(){
            this.SellerStats.last_time_read = parseInt(this.$cookies.get('read_news'));
            const timeOut = isNaN(this.SellerStats.last_time_read) ? true:(parseInt(this.SellerStats.last_time_read) < parseInt(this.SellerStats.last_update_news));
            this.showNews = (this.SellerStats.showNews && timeOut );
        }
    },
    mounted(){
        this.fetchData();
    }
}
</script>
<style scoped>
.key{
  text-transform: none;
}
</style>
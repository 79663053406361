<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <v-layout row wrap>
    <template>
      <v-flex
        sm=12
        xs=12
        md=12
        lg=12
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols=12>
                <v-switch
                  v-model="showLog"
                  label="Show logs ?"
                  color="orange darken-3"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            item-key="transID"
            :loading="Overlay"
            loading-text="Loading... Please wait"
            :footer-props="{'items-per-page-options':[15,30,50,100]}"
            :items-per-page="15"
          >
            <template v-slot:[`item.transData`]="{ item }">
                <v-btn
                    text
                    :color="(item.transData.method === 'CHARGE') ? 'red':'green'"
                    x-small
                    >
                  <v-icon left v-if="(item.transData.method === 'CHARGE')">fas fa-minus</v-icon>
                  <v-icon left v-else>fas fa-plus</v-icon>
                  {{item.transData.method}}
                </v-btn>
                <v-btn
                    text
                    color=white
                    x-small
                    outlined
                    >
                  {{item.transData.type}} ({{CalcTotal(item.transData)}})
                  <v-icon right v-if="(item.transData.type === 'BALANCE')">fas fa-dollar-sign</v-icon>
                  <v-icon right v-else>fas fa-key</v-icon>
                </v-btn>
                <v-btn
                    text
                    :color="(item.transData.method === 'CHARGE') ? 'red lighten-1':'green lighten-1'"
                    small
                    >
                  OLD: {{item.transData.old}}
                </v-btn>
                <v-btn
                    text
                    :color="(item.transData.method === 'CHARGE') ? 'red lighten-2':'light-green accent-2'"
                    small
                    >
                  NEW: {{item.transData.new}}
                </v-btn>
                <template v-if="item.transData.log && showLog">
                  <v-textarea
                    v-model="item.transData.log"
                    label="Transaction Log"
                    readonly
                    rows="2"
                  />
                  <!-- <v-alert
                    border="left"
                    colored-border
                    :color="(item.transData.method === 'CHARGE') ? 'red lighten-1':'green lighten-1'"
                    elevation="1"
                    dark
                  >
                    {{item.transData.log}}
                  </v-alert> -->
                </template>
                <template v-if="item.refunded">
                  <v-btn
                      color="red"
                      class="ma-1 text-center justify-center"
                      small
                      text
                    >
                      Refund TransCode: {{item.refund_transcode}}
                    </v-btn>
                </template>
            </template>
            <template v-slot:[`item.transTime`]="{ item }">
                <v-btn
                    text
                    color="orange"
                    small
                    >
                  {{doParseTime(item.transTime)}}
                </v-btn>
                <template v-if="item.refunded">
                  <v-btn
                      color="red"
                      class="ma-1 text-center justify-center"
                      small
                      text
                    >
                      Transaction Refunded
                    </v-btn>
                </template>
                <template v-if="item.can_refund">
                  <div>
                    <v-progress-linear
                      :value="item.time_left"
                      height="25"
                      class="ma-1"
                      color="green"
                    >
                      {{ item.time_left.toFixed(2) }} %
                    </v-progress-linear>
                    <v-btn
                      color="green"
                      class="ma-1"
                      small
                      @click="doRefund(item.transID)"
                    >
                      Refund
                    </v-btn>
                  </div>
                </template>
            </template>
        </v-data-table>
        </v-card>
      </v-flex>
    </template>
    </v-layout>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
export default {
  name: 'VipKey',
  directives: { clipboard },
  components: {
  },
  computed: {
      ...mapState('auth', ['status']),
      ...mapState({
          alert: state => state.alert
      }),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    async doRefund(transID){
      const confirm = await this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' });
      if(!confirm) return;
      this.Overlay = true;
      fetchData(`${this.$API}/api/Transaction/refund`,{
        transID,
      }).then((json)=>{
        const message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success === true){
            this.success(message);
            this.fetchData();
          }else{
            this.error(message);
          }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error(`SERVER ERROR !!!\n Error: ${err.message}`);
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    CalcTotal(data){
      const old_value = parseInt(data.old); const new_value = parseInt(data.new);

      return (new_value-old_value);
    },
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Transaction`,request).then((resp)=>{
          this.items = resp;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doParseTime: function(data){
      var dt = new Date(data*1000);
      return dt.toLocaleString();
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
          delete obj[propName];
        }
      }
      return obj
    },
  },
  data : () => ({
    showLog: true,
    listKeys: "",
    isTestKey: true,
    menu: false,
    valid: false,
    showFilter: {
      username: "All"
    },
    newKey:{
      num: 1,
      package: 1
    },
    extraTimeAll:true,
    addExtraMenu: false,
    extraTimeKey: "",
    extraTime: 0,
    extraTimeExpired: false,
    modifyMode: false,
    Overlay: false,
    dialog: false,
    search: '',
    headers: [
						{ text: 'Transaction', align: 'center', sortable: true,value: 'transCode',width:"15%"},
            { text: 'Detail', align: 'center', sortable: true,value: 'transData',width:"65%"},
            { text: 'Time', align: 'right', sortable: true,value: 'transTime',width:"10%" },
        ],
    items: [],
  }),
  watch:{
  },
  mounted(){
    this.fetchData();
  }
}
</script>
<style>
</style>
import { authService } from '../../services';
import router from '../../router'
const __API__ = localStorage.getItem('Token');
const state = __API__
    ? { status: { loggedIn: true, token:__API__} }
    : { status: {} };
    const actions = {
        login({ dispatch, commit }, { API,username, password, googleAuth, hashsec }) {
            authService.login(API,username, password, googleAuth,hashsec)
                .then(
                    token => {
                        localStorage.setItem('Token', token);
                        commit('loginSuccess', token);
                        router.push('/');
                        setTimeout(() => {
                            dispatch('alert/success', 'Login successful !!!', { root: true });
                        })
                    },
                    error => {
                        if(error.qr && error.hashsec){
                            commit('login2FARequired', error);    
                            dispatch('alert/error', 'Please add 2fa before login !!!', { root: true });
                        }else{
                            commit('loginFailure', error);
                            dispatch('alert/error', error, { root: true });
                        }
                    }
                );
        },
        logout({ commit }) {
            authService.logout();
            commit('logout');
            router.push('/login');
        },
    };
    
    const mutations = {
        loginSuccess(state, token) {
            state.status = { loggedIn: true,token:token };
        },
        login2FARequired(state,error) {
            state.status = {qr:error.qr,hashsec:error.hashsec};
        },
        loginFailure(state,error) {
            state.status = {error:error};
        },
        logout(state) {
            state.status = {};
        },
    };
    
    export const auth = {
        namespaced: true,
        state,
        actions,
        mutations
    };
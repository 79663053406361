import Vue from 'vue'
import VueRouter from 'vue-router'
import VipKey from '../views/VipKey.vue'
import Login from '../views/Login.vue'
import Transaction from '../views/Transaction.vue'
import Shop from '../views/Shop.vue'
import Purchased from '../views/Purchased.vue'
import Stream from '../views/PubKey.vue'
import Logs from '../views/Logs.vue'
//import Dev from '../views/Dev.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'keys',
    component: VipKey
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: Transaction
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/purchased',
    name: 'Purchased',
    component: Purchased
  },
  {
    path: '/stream',
    name: 'Stream',
    component: Stream
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const LoginPage = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('Token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  if(LoginPage && loggedIn){
    return next('/');
  }
  next();
})
export default router
